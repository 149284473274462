import {
  CarOutlined,
  CopyOutlined,
  HomeOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import get from "lodash/get";
import paths from "path";
import React from "react";
import { hierarchize } from "./library/hierarchical";

const keyName = "key";
const pathName = "path";
const uniqueKeyName = "uniqueKey";

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + "." + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, "");
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize(
  {
    key: "home",
    name: "主页",
    icon: <HomeOutlined />,
    path: "/",
    component: React.lazy(() => import("./pages/Dashboard")),
    children: [
      {
        key: "login",
        name: "Login",
        isPublic: true,
        isHidden: true,
        component: React.lazy(() => import("./pages/Login")),
      },
      {
        key: "profile",
        name: "个人信息",
        isHidden: true,
      },
      {
        key: "order",
        name: "订单",
        icon: <CopyOutlined />,
        requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
        children: [
          {
            key: "waybill",
            name: "运单",
            component: React.lazy(() => import("./pages/Order/Waybill")),
            requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
          },
          {
            key: "tracking",
            name: "跟踪表",
            component: React.lazy(() => import("./pages/Order/TrackingSheet")),
            requiredRoles: ["DISPATCH", "TRACK_VIEW", "ADMIN"],
          },
        ],
      },
      {
        key: "transportation",
        name: "运输",
        icon: <CarOutlined />,
        requiredRoles: ["DISPATCH", "CONSIGNMENT_VIEW", "ADMIN"],
        children: [
          {
            key: "consignment",
            name: "托运单",
            component: React.lazy(() =>
              import("./pages/Transportation/Consignment")
            ),
            requiredRoles: ["DISPATCH", "CONSIGNMENT_VIEW", "ADMIN"],
          },
          {
            key: "autoConsigning",
            name: "自动发运",
            requiredRoles: ["DISPATCH", "ADMIN"],
          },
        ],
      },
      {
        key: "bill",
        name: "账单",
        icon: <CopyOutlined />,
        requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
        children: [
          {
            key: "receivable",
            name: "应收",
            component: React.lazy(() =>
              import("./pages/Bill/Receivable/Receivable")
            ),
            requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
          },
        ],
      },
      {
        key: "allocation",
        name: "调度",
        icon: <CopyOutlined />,
        requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
        children: [
          {
            key: "usershippedproducts",
            name: "客户发运产品",
            component: React.lazy(() =>
              import(
                "./pages/Allocation/Usershippedproducts/Usershippedproducts"
              )
            ),
            requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
          },
          {
            key: "waybillallocAtionrule",
            name: "运单派单规则",
            component: React.lazy(() =>
              import(
                "./pages/Allocation/WaybillallocAtionrule/WaybillallocAtionrule"
              )
            ),
            requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
          },
        ],
      },
      {
        key: "quotation",
        name: "报价",
        icon: <CopyOutlined />,
        requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
        children: [
          {
            key: "quotationReceivable",
            name: "应收报价",
            component: React.lazy(() =>
              import(
                "./pages/Quotation/QuotationReceivable/QuotationReceivable"
              )
            ),
            requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
          },
          {
            key: "quotationPayable",
            name: "应付报价",
            component: React.lazy(() =>
              import("./pages/Quotation/QuotationPayable/QuotationPayable")
            ),
            requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
          },
          {
            key: "details",
            name: "详情明细",
            path: "/quotation/detail/:guid?",
            component: React.lazy(() =>
              import("./pages/Quotation/Details/Details")
            ),
            requiredRoles: ["DISPATCH", "ORDER_VIEW", "ORDER_EDIT", "ADMIN"],
            isHidden: true,
          },
        ],
      },
      {
        key: "crm",
        name: "客户管理",
        icon: <UsergroupAddOutlined />,
        requiredRoles: ["ADMIN"],
        children: [
          {
            key: "registration",
            name: "新客户注册",
            component: React.lazy(() => import("./pages/Registration")),
            requiredRoles: ["ADMIN"],
          },
          {
            key: "client",
            name: "客户管理",
            component: React.lazy(() => import("./pages/Client/UserMsg")),
            requiredRoles: ["ADMIN"],
          },
          {
            key: "store",
            name: "客户门店",
            component: React.lazy(() =>
              import("./pages/Customerstore/UserStore")
            ),
            requiredRoles: ["ADMIN"],
          },
          {
            key: "customerusers",
            name: "客户用户",
            component: React.lazy(() =>
              import("./pages/Customerusers/Customerusers")
            ),
            requiredRoles: ["ADMIN"],
          },
        ],
      },
      {
        key: "management",
        name: "系统管理",
        icon: <SettingOutlined />,
        requiredRoles: ["ADMIN"],
        children: [
          {
            key: "carrier",
            name: "承运商产品",
            component: React.lazy(() => import("./pages/managment/Carrier")),
            requiredRoles: ["ADMIN"],
          },
          {
            key: "jdOpen",
            name: "京东授权",
            component: React.lazy(() => import("./pages/managment/JdOpen")),
            requiredRoles: ["ADMIN"],
          },
          {
            key: "authority",
            name: "权限",
            requiredRoles: ["ADMIN"],
          },
        ],
      },
    ],
  },
  null,
  pathGenerator
);

export default routeConfig;
